import './index.less';

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useLocation } from '@reach/router';

import { Col, Row, Skeleton } from 'antd';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Fade from 'react-reveal/Fade';
import Sticky from 'react-sticky-el';

import axios from '../../api/axios';
import Breakpoint from '../../utils/breakpoint';
import FAQItem from './faqItem';

const AllFAQs = () => {
  const lang = i18n.language.toLowerCase();
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const [activeSection, setActiveSection] = useState(0);
  const [keyFound, setKeyFound] = useState(false);

  const location = useLocation();
  const { t } = useTranslation();
  const sectionsRef = useRef([]);
  const itemsRef = useRef([]);

  const data = [
    {
      title: t('franchise_faq_financials_title'),
      desc: t('franchise_faq_financials_desc'),
      slug: 'financials',
      order: 1,
      content: [
        {
          id: 1,
          slug: 'financials-1',
          title: t('franchise_faq_1_title'),
          content: t('franchise_faq_1_desc'),
        },
        {
          id: 2,
          slug: 'financials-2',
          title: t('franchise_faq_2_title'),
          content: t('franchise_faq_2_desc'),
        },
        {
          id: 3,
          slug: 'financials-3',
          title: t('franchise_faq_3_title'),
          content: t('franchise_faq_3_desc'),
        },
        {
          id: 4,
          slug: 'financials-4',
          title: t('franchise_faq_4_title'),
          content: t('franchise_faq_4_desc'),
        },
      ],
    },
    {
      title: t('franchise_faq_ourfranchisemodels_title'),
      desc: t('franchise_faq_ourfranchisemodels_desc'),
      slug: 'frnchise-models',
      order: 2,
      content: [
        {
          id: 5,
          slug: 'frnchise-models-1',
          title: t('franchise_faq_5_title'),
          content: t('franchise_faq_5_desc'),
        },
        {
          id: 6,
          slug: 'frnchise-models-2',
          title: t('franchise_faq_6_title'),
          content: t('franchise_faq_6_desc'),
        },
        {
          id: 7,
          slug: 'frnchise-models-3',
          title: t('franchise_faq_7_title'),
          content: t('franchise_faq_7_desc'),
        },
        {
          id: 8,
          slug: 'frnchise-models-4',
          title: t('franchise_faq_8_title'),
          content: t('franchise_faq_8_desc'),
        },
        {
          id: 9,
          slug: 'frnchise-models-5',
          title: t('franchise_faq_9_title'),
          content: t('franchise_faq_9_desc'),
        },
        {
          id: 10,
          slug: 'frnchise-models-6',
          title: t('franchise_faq_10_title'),
          content: t('franchise_faq_10_desc'),
        },
        {
          id: 11,
          slug: 'frnchise-models-7',
          title: t('franchise_faq_11_title'),
          content: t('franchise_faq_11_desc'),
        },
        {
          id: 12,
          slug: 'frnchise-models-8',
          title: t('franchise_faq_12_title'),
          content: t('franchise_faq_12_desc'),
        },
      ],
    },
    {
      title: t('franchise_faq_settingup_title'),
      desc: t('franchise_faq_settingup_desc'),
      slug: 'setting-up',
      order: 3,
      content: [
        {
          id: 13,
          slug: 'setting-up-1',
          title: t('franchise_faq_13_title'),
          content: t('franchise_faq_13_desc'),
        },
        {
          id: 14,
          slug: 'setting-up-2',
          title: t('franchise_faq_14_title'),
          content: t('franchise_faq_14_desc'),
        },
        {
          id: 15,
          slug: 'setting-up-3',
          title: t('franchise_faq_15_title'),
          content: t('franchise_faq_15_desc'),
        },
        {
          id: 16,
          slug: 'setting-up-4',
          title: t('franchise_faq_16_title'),
          content: t('franchise_faq_16_desc'),
        },
        {
          id: 17,
          slug: 'setting-up-5',
          title: t('franchise_faq_17_title'),
          content: t('franchise_faq_17_desc'),
        },
        {
          id: 18,
          slug: 'setting-up-6',
          title: t('franchise_faq_18_title'),
          content: t('franchise_faq_18_desc'),
        },
        {
          id: 19,
          slug: 'setting-up-7',
          title: t('franchise_faq_19_title'),
          content: t('franchise_faq_19_desc'),
        },
        {
          id: 20,
          slug: 'setting-up-8',
          title: t('franchise_faq_20_title'),
          content: t('franchise_faq_20_desc'),
        },
        {
          id: 21,
          slug: 'setting-up-9',
          title: t('franchise_faq_21_title'),
          content: t('franchise_faq_21_desc'),
        },
        {
          id: 22,
          slug: 'setting-up-10',
          title: t('franchise_faq_22_title'),
          content: t('franchise_faq_22_desc'),
        },
        {
          id: 23,
          slug: 'setting-up-11',
          title: t('franchise_faq_23_title'),
          content: t('franchise_faq_23_desc'),
        },
        {
          id: 24,
          slug: 'setting-up-12',
          title: t('franchise_faq_24_title'),
          content: t('franchise_faq_24_desc'),
        },
        {
          id: 25,
          slug: 'setting-up-13',
          title: t('franchise_faq_25_title'),
          content: t('franchise_faq_25_desc'),
        },
        {
          id: 26,
          slug: 'setting-up-14',
          title: t('franchise_faq_26_title'),
          content: t('franchise_faq_26_desc'),
        },
        {
          id: 27,
          slug: 'setting-up-15',
          title: t('franchise_faq_27_title'),
          content: t('franchise_faq_27_desc'),
        },
        {
          id: 28,
          slug: 'setting-up-16',
          title: t('franchise_faq_28_title'),
          content: t('franchise_faq_28_desc'),
        },
        {
          id: 29,
          slug: 'setting-up-17',
          title: t('franchise_faq_29_title'),
          content: t('franchise_faq_29_desc'),
        },
        {
          id: 30,
          slug: 'setting-up-18',
          title: t('franchise_faq_30_title'),
          content: t('franchise_faq_30_desc'),
        },
        {
          id: 31,
          slug: 'setting-up-19',
          title: t('franchise_faq_31_title'),
          content: t('franchise_faq_31_desc'),
        },
      ],
    },
    {
      title: t('franchise_faq_referral_title'),
      desc: t('franchise_faq_referral_desc'),
      slug: 'referrals',
      order: 4,
      content: [
        {
          id: 32,
          slug: 'referrals-1',
          title: t('franchise_faq_32_title'),
          content: t('franchise_faq_32_desc'),
        },
      ],
    },
    {
      title: t('franchise_faq_chooseritual_title'),
      desc: t('franchise_faq_chooseritual_desc'),
      slug: 'why-choose-ritual',
      order: 5,
      content: [
        {
          id: 33,
          slug: 'why-choose-ritual-1',
          title: t('franchise_faq_33_title'),
          content: t('franchise_faq_33_desc'),
        },
        {
          id: 34,
          slug: 'why-choose-ritual-2',
          title: t('franchise_faq_34_title'),
          content: t('franchise_faq_34_desc'),
        },
      ],
    },
  ];

  const collapseNodesArray = [];

  React.useEffect(() => {
    if (location.hash) {
      const hasFAQIndex = location.hash.split('#')[1];
      setActiveKey([hasFAQIndex]);

      setTimeout(() => {
        if (itemsRef.current[hasFAQIndex]) {
          itemsRef.current[hasFAQIndex].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 500);
    }
  }, [location, itemsRef, setActiveKey]);

  return (
    <div className="faq-all">
      <div className="container">
        {/* Loading FAQ from endpoint */}
        {loading && <Skeleton active />}
        <Row type="flex" align="top" gutter={60} className="block">
          <Breakpoint min="lg">
            <Col xs={24} sm={24} md={24} lg={8}>
              <Sticky
                stickyStyle={{
                  marginTop: '120px',
                  paddingBottom: '200px',
                }}
                topOffset={-100}
                boundaryElement=".block"
              >
                <div className="faq-nav">
                  {!loading &&
                    data.map((section, index) => (
                      <div
                        key={index}
                        className={`faq-nav-item ${activeSection === index && 'selected'}`}
                        onClick={() => {
                          sectionsRef.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' });
                        }}
                      >
                        {section.title}
                      </div>
                    ))}
                </div>
              </Sticky>
            </Col>
          </Breakpoint>
          <Col xs={24} sm={24} md={24} lg={16}>
            {/* List of FAQs */}
            <Fade>
              {!loading &&
                data.map((section, index) => (
                  <div key={index} className="faq-target">
                    <Breakpoint min="lg">
                      <FAQItem
                        onVisible={() => {
                          if (index > 0) {
                            setActiveSection(index - 1);
                          } else {
                            setActiveSection(0);
                          }
                        }}
                        onHide={() => {
                          setActiveSection(index);
                        }}
                        expandable={false}
                        section={section}
                        itemsRef={itemsRef}
                        itemIndex={index}
                        sectionsRef={sectionsRef}
                        collapseNodesArray={collapseNodesArray}
                        activeKey={activeKey}
                        onAccordionChange={(key) => {
                          setActiveKey(key);
                        }}
                      />
                    </Breakpoint>

                    <Breakpoint max="lg">
                      <FAQItem
                        expandable
                        section={section}
                        itemsRef={itemsRef}
                        itemIndex={index}
                        sectionsRef={sectionsRef}
                        collapseNodesArray={collapseNodesArray}
                        activeKey={activeKey}
                        onAccordionChange={(key) => {
                          setActiveKey(key);
                        }}
                      />
                    </Breakpoint>
                  </div>
                ))}
            </Fade>
          </Col>
        </Row>
      </div>
    </div>
  );
};

AllFAQs.propTypes = {
  t: PropTypes.func,
  type: PropTypes.string,
};

export default AllFAQs;
