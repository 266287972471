/* eslint-disable */
import './index.less';

import { DownOutlined, MinusOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons';

import { Collapse } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DownChevronImg from '../../../static/svg/chevron-down.svg';
import UpChevronImg from '../../../static/svg/chevron-up.svg';

const { Panel } = Collapse;

const FAQItem = ({
  section,
  expandable,
  itemsRef,
  sectionsRef,
  itemIndex,
  activeKey,
  collapseNodesArray,
  onAccordionChange,
  onVisible,
  onHide,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(!expandable);
  const [isVisible, setIsVisible] = useState(true);
  const wrapperRef = useRef(null);
  useEffect(() => {
    const onScroll = (e) => {
      const topPosition = wrapperRef.current.getBoundingClientRect().top;
      if (topPosition < 120 && isVisible) {
        setIsVisible(false);
        if (onHide) {
          onHide();
        }
      } else if (topPosition > 200 && !isVisible) {
        setIsVisible(true);
        if (onVisible) {
          onVisible();
        }
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [isVisible]);

  useEffect(() => {
    //if the active key array contains the section.content array item id, set expanded to true
    if (activeKey)
      if (
        section.content.find((item) => {
          return activeKey.indexOf(String(item.id)) > -1;
        })
      ) {
        setExpanded(true);
      }
  }, [activeKey]);

  const getItems = useCallback(() => {
    return (
      <Collapse
        activeKey={activeKey}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
        bordered={false}
        ref={(node) => {
          collapseNodesArray?.push({
            node,
            id: section?.slug,
          });
        }}
        onChange={(key) => {
          onAccordionChange(key);
        }}
      >
        {section?.content &&
          section?.content?.map((item, sIndex) => (
            <Panel
              header={`${item.title}`}
              key={item.id - 1}
              id={item.slug}
              style={{ position: 'relative', backgroundColor: '#F1F1F1', marginBottom: '10px', borderRadius: '10px' }}
            >
              <div
                className="faq-item-anchor"
                style={{ position: 'absolute', top: -100 }}
                ref={(el) => (itemsRef.current[item.id - 1] = el)}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: item.content,
                }}
              />
            </Panel>
          ))}
      </Collapse>
    );
  }, [activeKey, itemsRef]);

  return (
    <div ref={wrapperRef} className={`faq-section ${expanded && 'faq-section-expanded'}`}>
      <div className="faq-anchor" ref={(el) => (sectionsRef.current[itemIndex] = el)} />
      <div className="faq-section-title" onClick={() => expandable && setExpanded(!expanded)}>
        <span>{section?.title}</span>

        {expandable && (
          <div className="faq-section-expand">
            {expanded ? <img src={UpChevronImg} /> : <img src={DownChevronImg} />}
          </div>
        )}
      </div>

      <div className="faq-section-desc" onClick={() => expandable && setExpanded(!expanded)}>
        {section?.desc}
      </div>
      {/* FAQ Item */}
      {expanded && <div className="accordion">{getItems()}</div>}
    </div>
  );
};
FAQItem.propTypes = {
  t: PropTypes.func,
  type: PropTypes.string,
};

export default FAQItem;
